export default [
    {
        path: '/proyectos',
        meta: { nombre: 'Proyectos' },
        name: 'manager.proyectos',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/proyectos').then(m => m.default || m),
    },
    {
        path: '/crear-proyecto',
        meta: { nombre: 'Proyectos' },
        name: 'manager.crear-proyecto',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/proyectos/crearProyecto').then(m => m.default || m),
    },
    {
        path: '/proyectos/asignar-permisos',
        meta: { nombre: 'Proyectos' },
        name: 'manager.asignar-permisos',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/proyectos/asignarPermisos').then(m => m.default || m),
    }
]