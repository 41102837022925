export default [
    {
        path: '/usuarios',
        meta: { nombre: 'Usuarios' },
        name: 'manager.users',
        component: () => import(/* webpackChunkName: "usuarios" */ '../../pages/usuarios').then(m => m.default || m),
    },
    {
        path: '/usuarios/crear',
        meta: { nombre: 'Usuarios' },
        name: 'manager.users.crear',
        component: () => import(/* webpackChunkName: "usuarios" */ '../../pages/usuarios/usuariosCrear').then(m => m.default || m),
    },
    {
        path: '/usuarios/:idUser/editar',
        meta: { nombre: 'Usuarios' },
        name: 'manager.users.editar',
        component: () => import(/* webpackChunkName: "usuarios" */ '../../pages/usuarios/usuariosCrear').then(m => m.default || m),
    }
]