<template>
    <div class="menu-lateral border-right">
        <div class="row mx-0">
            <div class="col-12 px-0">
                <div class="space-logo d-middle-center">
                    <img src="/img/auth/logo_blanco.svg" class="obj-cover" height="40" style="max-width:100%;" />
                </div>
            </div>
        </div>
        <div class="overflow-auto scroll-none menu pt-3" style="height:calc(100vh - 62px);">
            <div class="row mx-0 justify-center mb-4">
                <img :src="project.imagen" class="br-4 obj-cover img-proyect" width="100" height="100" />
                <p class="col-12 text-center f-18 text-black f-600 mt-3">
                    {{ project.nombre }}
                </p>
            </div>
            <div class="row mx-0">
                <div class="col-6 text-center mb-3">
                    <small class="text-grey f-10">
                        Cedis
                    </small>
                    <p class="text-grey">
                        <i class="icon-cedis f-20" />
                        <b class="f-16">
                            {{ agregarSeparadoresNumero(project.cedis) }}
                        </b>
                    </p>
                </div>
                <div class="col-6 text-center mb-3">
                    <small class="text-grey f-10">
                        Productos
                    </small>
                    <p class="text-grey">
                        <i class="icon-product f-20" />
                        <b class="f-16">
                            {{ agregarSeparadoresNumero(project.productos) }}
                        </b>
                    </p>
                </div>
                <div class="col-6 text-center">
                    <small class="text-grey f-10">
                        Vendedores
                    </small>
                    <p class="text-grey">
                        <i class="icon-leechero f-20" />
                        <b class="f-16">
                            {{ agregarSeparadoresNumero(project.tiendas) }}
                        </b>
                    </p>
                </div>
                <div class="col-6 text-center">
                    <small class="text-grey f-10">
                        Clientes
                    </small>
                    <p class="text-grey">
                        <i class="icon-cliente f-20" />
                        <b class="f-16">
                            {{ agregarSeparadoresNumero(project.clientes) }}
                        </b>
                    </p>
                </div>
            </div>
            <hr class="border mx-3 my-3" />
            <div v-for="(item, idx) in items" :key="idx" class="row mx-0 px-3 my-3 justify-center">
                <router-link
                v-slot="{ navigate, isActive }"
                :data-xd="item.ruta"
                :to="{ name: `${item.ruta}`, params: item.params || {} }"
                :class="{'router-link-active': subIsActive(item.active)}"
                >
                    <a
                    class="d-middle position-relative cr-pointer pl-2"
                    :class="{'router-link-exact-active router-link-active': isActive}"
                    @click="navigate"
                    >
                        <div v-show="item.cant>0" class="badge">
                            {{ item.cant }}
                        </div>
                        <div class="px-0 text-center" style="width:25px">
                            <i :class="`${item.size ? `f-${item.size}` : 'f-18'}  ${item.icon}`" />
                        </div>
                        <div class="col px-2 f-12">
                            {{ item.titulo }}
                        </div>
                    </a>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Service from '~/services/proyectos/proyectos'

export default {
    data(){
        return {
            project: {
                nombre: null,
                imagen: null,
                cedis: 0,
                productos: 0,
                tiendas: 0,
                clientes: 0
            },
            items: [
                //{ titulo: 'Proyectos', ruta: 'manager.proyectos', icon: 'icon-proyectos', items: 87 },

                { titulo: 'Administradores', ruta: 'manager.administradores', icon: 'icon-user-ok', items: 87 },
                { titulo: 'Asignación de permisos', ruta: 'manager.asignacion-permisos', icon: 'icon-unidades', items: 87 },
                { titulo: 'Lugares de operación', ruta: 'manager.lugares-operacion', icon: 'icon-location', items: 87 },
                { titulo: 'Usuarios Manager', ruta: 'manager.users', icon: 'icon-user-outline', items: 85},
                { titulo: 'Creacíon de permisos', ruta: 'manager.permisos', icon: 'icon-key'},
                { titulo: 'Feature flags', ruta: 'manager.features-flags', icon: 'icon-switch mr-2', size: 12},
                { titulo: 'Información general', ruta: 'manager.informacion-general', icon: 'icon-info-circled-alt mr-2',},
				{ titulo: 'Modulos disponibles', ruta: 'modulos', icon: 'icon-box mr-2'}
            ]
        }
    },
    computed: {
        isActive(){
            return this.$route.name
        }
    },
    mounted(){
        this.getProject();
    },
    methods: {
        async getProject(){
            try {
                const {data} = await Service.getDataProjectNavbar(this.$usuario.proyecto);
                this.project.imagen = data.project.imagen_firmada;
                this.project.nombre = data.project.nombre;
                this.project.cedis = data.project.cedis;
                this.project.productos = data.project.productos;
                this.project.tiendas = data.project.tiendas;
                this.project.clientes = data.project.clientes;
            } catch(e){
                this.error_catch(e);
            }
        },
        ir(ruta){
            this.$router.push({ name: ruta })
        },
        subIsActive(input){
            const paths = Array.isArray(input) ? input : [input]
            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0 || this.$route.path.indexOf(path) === 1 || this.$route.name === path
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.menu-lateral{
    position: fixed;
    width: 234px;
    height: 100vh;
    z-index: 0;
    background-color: #FFFFFF;
    .space-logo{
        height: 60px;
        background-color: #333333;
    }
    .img-proyect{
        border: 3px solid #0F3E85;
    }
}
.badge{
    position: absolute;
    top: 2px;
    left: 35px;
    border-radius: 20px!important;
    border: 1px solid #fff;
    color: #fff;
    background: linear-gradient(to right, #FF9D32 5%, #FF2525)
}
.menu{
    a{
        width:100%;
        height:30px;
        border-radius: 4px;
        color: var(--color-gris);
        text-decoration: none !important;
        &:hover{
            background:#333333;
            color: #FFFFFF !important;
        }
        &.router-link-exact-active.router-link-active{
            background:#333333;
            color: #FFFFFF !important;
        }
    }
}
</style>
