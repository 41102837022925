// Inicializar el proveedor de credenciales de Amazon Cognito
AWS.config.region = 'us-east-1'; // Región
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-1:1f833c74-8e35-40ff-b363-d53c50a13c95',
});
const aws = {
    Bucket: 'your-market-empresa',
    setBucket(Bucket = 'your-market-empresa'){
        this.Bucket = Bucket
    },
    getInstance(){
        return new AWS.S3({
            apiVersion: '2006-03-01',
            params: {Bucket: this.Bucket}
        })
    },
    getHash(namespace = ''){
        return `${namespace === '' ? '' : `${namespace}/`}${new Date().getTime()}-${Math.random().toString(16).substr(2)}`
    },
    b64toBlob(dataURI,type = 'image/jpeg'){
        var byteString = atob(dataURI.split(',')[1])
        var ab = new ArrayBuffer(byteString.length)
        var ia = new Uint8Array(ab)
        for(var i = 0; i < byteString.length; i++){
            ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ab],{type})
    }
}
export default aws
