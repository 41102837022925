<template>
    <div class=" h-80 d-middle-center">
        <div class="col-auto f-15 text-center" style="width:205px;">
            <div class="br-5 mx-auto d-middle-center bg-whitesmoke" style="width:160px;height:160px;">
                <img :src="`/img/sin_datos/${icon}.${ext}`" alt="" />
            </div>
            <p>{{ mensaje }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mensaje: {
            type: String,
            default: 'Sin datos'
        },
        icon: {
            type: String,
            default: 'question-mark'
        },
        ext: {
            type: String,
            default: 'svg'
        }
    }
}
</script>

<style lang="css" scoped>
</style>
