<template>
    <div class="chat-general position-relative" :class="fondo?'bg-chat':''">
        <div class="overflow-auto pr-3 custom-scroll" :style="finalizado?'height:100%;':`height:calc(100% - ${notific?'58px':'90px'});`">
            <div v-for="(data, indice) in mensajes" :key="indice" class="row mx-0 px-2 my-3 no-gutters">
                <img
                v-show="data.user==1"
                class="rounded-circle obj-cover"
                height="32"
                width="32"
                :src="_.defaultTo(data.foto_miniatura, '/img/no-imagen/sin_user.png')"
                />
                <div class="col-9 ml-3" :class="data.user == 1 ? 'mr-auto' : 'ml-auto'">
                    <div class="row mx-0 justify-content-end">
                        <div class="col-auto px-0 br-5 chat-bubble" :class="data.user == 1 ? 'chat-bubble-left' : 'chat-bubble-right'">
                            <div v-show="data.comentario" class="row mx-0 my-1 f-13">
                                <div class="col">
                                    <div class="row mx-0 justify-content-end">
                                        <p class="f-15" style="word-break: break-all;" v-html="data.comentario" />
                                    </div>
                                    <div class="row mx-0 justify-content-end">
                                        <i class="" v-text="data.fecha" />
                                    </div>
                                </div>
                            </div>
                            <div v-show="data.img" class="row mx-0">
                                <div class="col p-2 cr-pointer">
                                    <!-- <img class="w-100 cr-pointer" :src="data.img" style="max-width:200px;max-height:200px;" @click="verImagen(data.img)" /> -->
                                    <viewer :images="images">
                                        <img v-for="src in images" class="img-chat" :src="src" :key="src" />
                                    </viewer>
                                </div>
                            </div>
                            <div v-if="data.user==1" class="chat-bubble-arrow-left" />
                            <div v-else class="chat-bubble-arrow-right" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="finalizado" class="row mx-2 bg-whitesmoke p-2 br-10">
                <span>Finalizado</span> 16 Jul. 2020 12:25 a.m.
            </div>
            <div v-if="false" class="row h-100 mx-0 align-items-center justify-content-center">
                <div class="text-center f-15 d-middle-center flex-column" style="width:225px;">
                    <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                        <img height="100" src="/img/sin_datos/question.svg" alt="" />
                    </div>
                    <p>Aún no te has comunicado con "YourMarket"</p>
                </div>
            </div>
        </div>
        <div v-show="!finalizado" class="w-100 p-2" style="bottom:0;" :class="fondo?'bg-chat':''">
            <div v-show="mensaje==2" class="row mx-0 px-2 mb-2">
                <div class="col br-20 bg-grro-general text-white text-center f-15">
                    Tiene 3 mensajes permitidos
                </div>
            </div>
            <div class="row mx-0 px-2">
                <div class="col">
                    <el-input v-model="input1" class="br-20" placeholder="Mensaje">
                        <i slot="suffix" class="icon-attach text-gr-red cr-pointer el-input__icon" />
                    </el-input>
                </div>
                <i class="icon-send text-gr-red cr-pointer my-auto f-20 mr-2" />
            </div>
            <div v-show="!notific">
                <div v-show="mensaje==1" class="row mx-0 px-2 mt-2">
                    <div class="col br-20 bg-grr-red text-white text-center f-15">
                        Tiene 3 mensajes permitidos
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import Vue from 'vue'
Vue.use(Viewer)
export default {
    props: {
        fondo: {
            type: Boolean,
            default: false
        },
        finalizado: {
            type: Boolean,
            default: false
        },
        notific: {
            type: Boolean,
            default: false
        },
        mensaje: {
            type: Number,
            default: 1
        }
    },
    data(){
        return {
            images: ['https://media.istockphoto.com/vectors/common-supermarket-grocery-products-flat-vector-illustration-set-vector-id1194272120'],
            input1: '',
            mensajes: [
                {
                    foto_miniatura: 'https://www.ashoka.org/sites/default/files/styles/medium_1600x1000/public/thumbnails/images/daniela-kreimer.jpg?itok=R89tVtb4',
                    fecha: '11 nov de 2001',
                    user: 1,
                    comentario: 'Hola esto es un comentario de prueba de un comentario normal y corriente'
                },
                {
                    foto_miniatura: 'https://www.ashoka.org/sites/default/files/styles/medium_1600x1000/public/thumbnails/images/daniela-kreimer.jpg?itok=R89tVtb4',
                    fecha: '11 nov de 2001',
                    user: 2,
                    comentario: 'Hola esto es un comentario de prueba de un comentario normal y corriente'
                },
                {
                    foto_miniatura: 'https://www.ashoka.org/sites/default/files/styles/medium_1600x1000/public/thumbnails/images/daniela-kreimer.jpg?itok=R89tVtb4',
                    fecha: '11 nov de 2001',
                    user: 1,
                    comentario: 'Hola esto es un comentario de prueba de un comentario normal y corriente'
                },
                {
                    foto_miniatura: 'https://www.ashoka.org/sites/default/files/styles/medium_1600x1000/public/thumbnails/images/daniela-kreimer.jpg?itok=R89tVtb4',
                    fecha: '11 nov de 2001',
                    user: 2,
                    comentario: 'Hola esto es un comentario de prueba de un comentario normal y corriente'
                },
                {
                    user: 2,
                    img: 'https://media.istockphoto.com/vectors/common-supermarket-grocery-products-flat-vector-illustration-set-vector-id1194272120'
                }
            ]
        }
    },
    methods:{
        verImagen(img){
            this.$refs.modalVerImagen.toggle(img)
        }
    }
}
</script>

<style lang="css" scoped>
.chat-general{
    /* width:28vw; */
    height:100%;
    /* padding-bottom: 90px; */
}
.bg-chat{
    background: #F0F0F0;
}
.chat-bubble{
    background: #F8FCFF;
    /* border: 1px solid var(--color-general); */
    border: 1px solid #F8FCFF;
}
.chat-bubble-left{
    background: #F5F5F5;
    /* border: 1px solid var(--color-general); */
    /* border: 2px solid #0892DD; */
}
.chat-bubble-right{
    background: var(--gr-r-general);
    /* border: 1px solid red; */
    color: white!important;
}
.chat-bubble-arrow-left {
    border-color: #F5F5F5 #00000000  #00000000 transparent;
    border-style: solid;
    border-width: 10px;
    top: 0px;
    height: 0;
    right: -10px;
    position: absolute;
    width: 0;
}
.chat-bubble-arrow-right {
    border-color: rgb(8, 88, 171) #00000000  #00000000 transparent;
    border-style: solid;
    border-width: 10px;
    top: 0px;
    height: 0;
    right: -10px;
    position: absolute;
    width: 0;
}
.img-chat{
    object-fit: cover;
    width:200px;
    max-width:100%;
    max-height: 200px;
    /* max-height:100%; */
}
</style>
