<template>
    <div class="row mx-0 my-3">
        <div class="col px-0">
            <div class="row">
                <!-- Cabecera izquierda -->
                <slot name="cabecera-izquierda" />
                <!-- Barra de búsqueda -->
                <div class="ml-auto col-auto d-middle">
                    <el-input
                    v-if="mostrarBuscador"
                    v-model="textoBusqueda"
                    placeholder="Buscar en el listado"
                    size="small"
                    prefix-icon="icon-search"
                    class="cabecera-busqueda mx-1"
                    />
                </div>
                <!-- Cabecera derecha -->
                <slot name="cabecera-derecha" />
            </div>
            <el-table
            v-loading="cargando"
            height="500px"
            :data="datosDinamicos"
            :class="claseSeleccionable"
            stripe
            empty-text="No se han encontrado registros"
            class="w-100 my-3 tabla-element"
            :header-row-class-name="classHeader"
            @cell-click="eventoSeleccion"
            @selection-change="eventoSeleccionMultiple"
            @sort-change="ordenarSegunCampo"
            >
                <!-- Columna índice -->
                <el-table-column v-if="mostrarIndice" type="index" min-width="30" align="center" />

                <!-- Columna de selección múltiple -->
                <el-table-column v-if="activarSeleccionMultiple" type="selection" min-width="30" />

                <!-- Slot para agregar columnas adicionales a la izquierda -->
                <slot name="adicionales-izquierda" />

                <!-- Slot para agregar columnas en el template. Por defecto mostrará las que se le pasen en el array "Columnas" -->
                <slot>
                    <el-table-column v-for="col in columnasDibujables"
                                     :key="col.id"
                                     :prop="col.valor"
                                     :label="col.titulo"
                                     :sortable="col.ordenar || true"
                                     :min-width="col.ancho || 180"
                                     :align="col.alinear"
                                     :fixed="col.fijar || false"
                                     :class-name="col.class"
                                     :formatter="col.formato"
                    />
                </slot>

                <!-- Slot para agregar columnas adicionales a la derecha -->
                <slot name="adicionales-derecha" />
            </el-table>
            <!-- Paginación -->
            <div v-if="usarPaginacion" class="d-flex justify-content-center mt-4">
                <el-pagination
                :current-page.sync="paginacion.paginaActual"
                :page-size.sync="configuracion.porPagina"
                :total="paginacion.total"
                :page-sizes="paginacion.selectorPaginado"
                :layout="paginacion.botones"
                background
                @current-change="validarPaginacion"
                @size-change="validarPaginacion"
                />
            </div>
        </div>
    </div>
</template>

<script>
var _ = require('lodash');
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
        columnas: {
            type: Array,
            default: () => []
        },
        classHeader: {
            type: String,
            default: () => ''
        },
        columnasFecha: {
            type: Object,
            default: () => ({})
        },
        mostrarBuscador: {
            type: Boolean,
            default: true
        },
        mostrarIndice: {
            type: Boolean,
            default: false
        },
        activarSeleccion: {
            type: Boolean,
            default: false
        },
        activarSeleccionMultiple: {
            type: Boolean,
            default: false
        },
        botones: {
            type: Array,
            default: () => []
        },
        configuracionUsuario: {
            type: Object,
            default: () => ({})
        },
        usarPaginacion: {
            type: Boolean,
            default: true
        },
        usarServidor: {
            type: Boolean,
            default: false
        },
        servidorData: {
            type: [Array, Object],
            default: () => {
                return {
                    current_page: 1,
                    per_page: 1,
                    total: 1,
                    data: []
                }
            }
        },
        cargando: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            datosDinamicos: [],
            datosFiltrados: [],
            textoBusqueda: '',
            totalDatos: 0,
            configuracion: {
                paginaActual: 1,
                porPagina: 25,
                selectorPaginado: [5, 10, 25, 50, 100],
                celdasSeleccionables: [0, 1, 2],
                ...this.opciones
            }
        }
    },
    computed: {
        /*
            Array de datos el cual pasará por un proceso de "limpieza", quitando todas las keys innecesarias (watch)
            Este será siempre el array de datos base para ejecutar búsquedas en la tabla.
            Este array es inmutable, a menos que cambien los datos del componente padre.
        */
        datosEstaticos(){
            return this.usarServidor
                ? _.cloneDeep(this.servidorData.data)
                : _.cloneDeep(this.data)
        },
        paginacion(){
            return {
                paginaActual: this.usarServidor
                    ? this.servidorData.current_page
                    : this.configuracion.paginaActual,

                porPagina: this.usarServidor
                    ? this.servidorData.per_page
                    : this.configuracion.porPagina,

                total: this.usarServidor
                    ? this.servidorData.total
                    : this.totalDatos,

                botones: this.usarServidor
                    ? 'prev, pager, next, jumper'
                    : 'sizes, prev, pager, next, jumper',

                selectorPaginado: this.configuracion.selectorPaginado,
            }
        },
        claseSeleccionable(){
            return this.activarSeleccion ? 'cr-pointer' : ''
        },
        columnasDibujables(){
            return this.columnas.filter(item => item.valor)
        },
        columnasBusqueda(){
            return this.columnas.flatMap(item => item.valor ?? item)
        },
        criteriosBusqueda(){
            return this.textoBusqueda.trim().split(' ')
        },
        textoBusquedaSinEspacios(){
            return this.textoBusqueda.trim()
        },
        campoBusquedaEstaVacio(){
            return _.isEmpty(this.textoBusqueda.trim())
        }
    },
    watch: {
        /*
            Array copia de datosEstaticos el cual mostrará los datos filtrados.
            El array estará cambiando a medida que se apliquen filtros.
        */
        datosEstaticos: {
            immediate: true,
            handler(datos){
                if (this.usarServidor){
                    this.datosDinamicos = _.cloneDeep(datos)
                } else {
                    this.paginar(datos)
                }
            }
        },
        /*
            Se aplica un handler al v-model del campo de búsqueda, para así ejecutar a través del debouncer
        */
        textoBusqueda: {
            handler(){
                this.debouncer()
            },
            deep: true
        }
    },
    methods: {
        /*
            Esta función vacía, recibirá el la función _.debounce del mounted, para invocarse cuando se necesite
        */
        debouncer: _.debounce(function(){
            this.validarBusqueda()
        }, 200),
        /*
            Ordena los campos de fecha, según el objeto enviado por props
        */
        ordenarSegunCampo({ prop, order }){
            const columna = this.columnasFecha[prop]

            if (!columna) return

            const array = _.isEmpty(this.datosFiltrados) ? this.datosEstaticos : this.datosFiltrados

            const ordenado = array
                .slice()
                .sort((a, b) => this.ordernarPorFecha(order, a[columna], b[columna])
                )

            this.paginar(ordenado)
        },
        ordernarPorFecha: (orden, valor1, valor2) => orden === 'descending'
            ? new Date(valor2) - new Date(valor1)
            : new Date(valor1) - new Date(valor2),
        /**
            Evento que se ejecuta si se presiona una fila. Debe estar activada la selección única
            @params = args [row, column, cell, event] (element table)
        */
        eventoSeleccion(...args){
            if (! this.configuracion.celdasSeleccionables
                .includes(args[2].cellIndex))
                return

            this.$emit('seleccion', args[0])
        },

        eventoSeleccionMultiple(seleccionados){
            this.$emit('seleccion', seleccionados)
        },
        /*
            Valida si el campo de búsqueda está vacío o tiene texto. Si está vacío, ejecuta el paginar.
            Si tiene texto, ejecuta la búsqueda.
        */
        validarBusqueda(){
            if (this.usarServidor){
                this.$emit('buscar', this.textoBusquedaSinEspacios)
            } else {
                this.validarPaginacion()
            }
        },
        /*
            Si es ServerSide, emitirá el evento paginar. Si no, invocará la función paginar
        */
        validarPaginacion(pagina){
            if (this.usarServidor)
                this.$emit('paginar', pagina)

            if (! this.usarServidor && ! this.campoBusquedaEstaVacio){
                this.ejecutarBusqueda()
                this.paginar(this.datosFiltrados)
            }

            if (! this.usarServidor && this.campoBusquedaEstaVacio)
                this.paginar(this.datosEstaticos)
        },
        /*
            El paginar tomará la página actual y comparará cuántos registros necesita por página.
            En base a ese cálculo, se toman los datos en el intérvalo necesario (página)
        */
        paginar(datos = []){
            this.datosDinamicos = this.usarPaginacion
                ? _.cloneDeep(datos.slice(
                    (this.paginacion.paginaActual - 1) * this.paginacion.porPagina,
                    this.paginacion.paginaActual * this.paginacion.porPagina
                ))
                : _.cloneDeep(datos)

            this.totalDatos = datos.length
        },
        /*
            Búsqueda en los registros a partir de los criterios de búsqueda dados en el campo buscar.
        */
        ejecutarBusqueda(){
            this.datosFiltrados = []

            this.datosEstaticos.forEach(registro => {
                let coincidencias = []

                // Se comienza a evaluar cada uno de los criterios en el texto de búsqueda
                this.criteriosBusqueda.forEach(criterio => {

                    // Cada item del objecto, se compara contra el criterio
                    Object.entries(registro).forEach(([key, item]) => {
                        if(!item)
                            return

                        if(!_.isEmpty(this.columnasBusqueda) && !this.columnasBusqueda.includes(key))
                            return

                        // Si es array se vuelve String
                        if(Array.isArray(item))
                            item = item.join(' ')

                        // Si es objecto, se vuelve String
                        if(typeof item === 'object' && item !== null)
                            item = JSON.stringify(item)

                        // Se aplica la búsqueda del criterio y si concuerda, se suma +1 al coincidencias de coincidencias
                        if (item.toString().toLowerCase().includes(criterio.toLowerCase()))
                            coincidencias.push(criterio)
                    })
                })

                // Se ordena el array de coincidencias y se toman los valores únicos (no repetidos)
                coincidencias = _.sortBy(_.uniq(coincidencias))

                // Las coincidencias siempre deben concordar con los criterios, entonces será agregada la fila al resultado final
                if (_.isEqual(coincidencias, _.sortBy(this.criteriosBusqueda)))
                    this.datosFiltrados.push(registro)
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .tabla-element {
        font-size: 12px;
    }
</style>
