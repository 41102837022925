import AdminConfigurar from './configurarAdmin/configurar'
import TenderoVipInformacion from "./informacion/tendero-vip";
import Ayuda from './ayuda/ayuda'
import Configurar from './configurar/configurar'
import Login from '~/pages/auth/login'
import Proyectos from './proyectos/proyectos'
import Permisos from './permisos/permisos'
import Usuarios from './usuarios/usuarios'
import Avatares from './avatares/avatares'
import FeatureFlags from './featureFlags/featureFlags'
import Administradores from './administradores/administradores'
import AsignacionPermisos from './asignacionPermisos/asignacionPermisos'
import lugaresOperacion from './lugaresOperacion/lugaresOperacion'
import InformacionGeneral from './informacionGeneral/informacionGeneral';
import Modulos from './modulos/modulos' ;
export default [
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: '/',
        name: 'home',
        component: ()=>import('../pages/Home.vue').then(m=>m.default || m),
        redirect: { name: 'manager.proyectos'}
    },
    ...AdminConfigurar,
    ...TenderoVipInformacion,
    ...Ayuda,
    ...Configurar,
    ...Proyectos,
    ...Usuarios,
    ...Avatares,,
    ...Permisos,
    ...FeatureFlags,
    ...Administradores,
    ...AsignacionPermisos,
    ...lugaresOperacion,
    ...InformacionGeneral,
	...Modulos   
]
