<template>
    <ValidationObserver ref="validacion" v-slot="{ valid }">
        <modal-lateral ref="comentarios" :titulo="titulo">
            <div class="row mx-0 text-general px-2">
                <div class="col" v-loading="loading" :element-loading-text="`${cargando}`">
                    <ValidationProvider v-slot="{errors}" rules="required" name="comentario">
                        <el-input
                        v-model="form.comentario"
                        placeholder="Por favor, escriba su comentario"
                        type="textarea"
                        :rows="3"
                        :maxlength="caracteres"
                        show-word-limit
                        />  
                        <span class="text-danger f-10">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <el-upload
                    ref="adjuntos"
                    slot="suffix"
                    class="upload-demo icono-subir"
                    action="#"
                    :limit="1"
                    :on-change="agregar_archivos"
                    :auto-upload="false"
                    accept="image/*, application/pdf"
                    >
                        <i class="icon-attach text-gr-red cr-pointer" />
                    </el-upload>
            </div>
            <div class="row mx-0 px-3 justify-content-end my-2">
                <button slot="reference" type="button" class="btn btn-sm br-20 text-white bg-gr-general" name="button" @click="crearComentario(valid)">
                    Comentar
                </button>
            </div>
            <div class="overflow-auto custom-scroll" style="max-height:calc(100vh - 200px)">
                <div v-for="(data, indice) in comentarios" :key="indice" class="row mx-0 px-2 my-3 no-gutters">
                    <img
                    class="rounded-circle obj-cover"
                    height="32"
                    width="32"
                    :src="_.defaultTo(data.imagen_firmado, '/img/no-imagen/sin_user.png')"
                    />
                    <div class="col chat-bubble ml-3" :class="data.estado == 'pagado' ? 'chat-bubble-pay' : '' ">
                        <div class="row mx-0 my-1 f-13 text-muted">
                            <div class="col-6 f-600">
                                <span v-if="!data.nombre" class="ml-2 text-general">COLOCAR NOMBRE DEL USUARIO</span>
                                <span class="f-16" v-text="data.nombre" />
                            </div>
                            <div v-if="data.tipo==1" class="col-12">
                                <p class="f-15" v-html="data.comentario" />
                            </div>
                            <div v-else>            
                                <div v-if="file_type(data.comentario) === 'application/pdf'" class="col-auto d-middle card-archivo bg-whitesmoke border br-4 m-2 px-2 cr-pointer" @click="openAdjunto(data.archivo_firmado)">                                 
                                    <i class="icon-file-pdf f-18 mr-2 text-danger" />
                                    {{nombrePdf(data.comentario)}}
                                </div>
                                <div v-else class="col p-2 cr-pointer">
                                    <img class="w-100 cr-pointer" :src="data.archivo_firmado" style="max-width:200px;max-height:200px;" @click="verImagen(data.archivo_firmado)" />
                                </div>

                            </div>
                            <div class="col-12 text-right">
                                <i class="text-muted" v-text="data.fecha" />
                            </div>
                        </div>
                        <div class="chat-bubble-arrow-left" />
                    </div>
                </div>
            </div>
        <modalImagen ref="modalVerImagen" />
        <visor-pdf ref="vPdf" />
        </modal-lateral>
    </ValidationObserver>
</template>
​
<script>
import Proyectos from '~/services/proyectos/proyectos'
import Aws from '~/services/aws'
import {mapGetters} from 'vuex'

export default {
    props: {
        titulo: {
            type: String,
            default: 'Comentarios'
        },
        id: {
            type: Number,
            default: 0
        },
        modelo: {
            type: Number,
            required: false
        },
        caracteres: {
            type: Number,
            default: 750
        },
        contador: {
            type: Number,
            default: 0
        }
    },
    data(){
        return {
            miRuta: 'comentarios',
            cargando: '',
            loading: false,
            archivo: null,
            activoFilter: Number,
            todos: 1,
            pagos: 2,
            form: {
                comentario: null,
                id_proyecto: this.id_proyecto,
                tipo:1
            },
            comentarios: []
        }
    },
       computed:{
        ...mapGetters({
            id_proyecto: 'proyectos/proyectos/id_proyecto',
        })
    },
    mounted(){
    },
    methods: {
        toggle(){
            this.limpiar()
            this.listarComentarios()
            this.$refs.comentarios.toggle()
        },
        async crearComentario(esValido){

            try {
            if (!esValido) return

                const { data } = await Proyectos.crearComentarios( this.form)
                this.notificacion('Éxito', data.mensaje, 'success')
                this.limpiar()
                this.listarComentarios()
            } catch (error){
                this.error_catch(error)
            }
        },
        async listarComentarios(){
            try {
                this.cargando = true
                const { data } = await Proyectos.listarComentarios({id_proyecto: this.id_proyecto})
                this.comentarios = data.comentarios
            } catch (error){
                this.error_catch(error)
            } finally {
                this.cargando = false

            }
        },
        async agregar_archivos(file, fileList){
            let nombreMayor100Carecteres = file.name.length > 100
            let tipo = null
            let tamano = null
            if (file.raw.type === 'application/pdf'){
                tipo = 3
                tamano = 20
            } else if (file.raw.type.split('/').slice(0, 1).pop() === 'image'){
                tipo = 2
                tamano = 1000
            } else {
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('No valido', 'El archivo no cumple con la extensión requerida(pdf ó imágenes)', 'warning')
                return
            }

            const limite = file.size / 1024 / 1024 <= tamano
            if (!limite){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('No valido', ` El archivo supera los ${tamano} Mb`, 'warning')
                return
            }

            if (nombreMayor100Carecteres){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('Error', `¡El nombre del archivo ${file.name} excede los 100 caracteres!`, 'error')
                return
            }
            this.form.tipo = 2
            this.archivo = file
            fileList.splice(fileList.indexOf(file), 1)
            this.guardarArchivo()
        },
        async guardarArchivo(){
            try {
                let ruta = `${this.miRuta}${Aws.getHash()}.${this.archivo.name}`
                // let ruta = `${this.miRuta}/${Aws.getHash()}.${this.archivo.name.split('.').pop()}`
                this.loading = true
                let resp = await Aws.getInstance().upload({
                    Key: ruta,
                    ContentType: this.archivo.type,
                    Body: this.archivo.raw
                }).on('httpUploadProgress',uploadEvent=>{
                    let porcentaje = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                    this.cargando = porcentaje
                }).promise()
                this.loading = false
                this.form.comentario = resp.Key
                this.form.tipo = 2
                this.crearComentario(true)

            } catch (e){
                this.loading = false
                this.form.tipo = 1
                this.error_catch(e)
            }
        },
        openAdjunto(pdf){
            this.$refs.vPdf.set_pdf(pdf)
        },
        verImagen(img){
            this.$refs.modalVerImagen.toggle(img)
        },
        nombrePdf(name){
            let array =  name.split('.')
            return array[array.length - 2]
        },
        limpiar(){
            this.form.comentario = null
            this.form.id_proyecto = this.id_proyecto
            this.form.tipo = 1
            this.$refs.validacion.reset()
        }
    }
}
</script>
​
<style lang="scss" scoped>
.chat-bubble{
    background: #F8FCFF;
    /* border: 1px solid var(--color-general); */
    border: 1px solid #F8FCFF;
}
.chat-bubble-pay{
    background: #F8FCFF;
    /* border: 1px solid var(--color-general); */
    border: 2px solid #0892DD;
}
.chat-bubble-right{
    background: #f9f9f9;
    border: 1px solid red;
}
.border-bottom-active{
    border-bottom: 3px solid #FF2525;
}
.card-archivo{
        max-width: 231px;
}
</style>
