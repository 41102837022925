<template>
    <ValidationObserver ref="validacion">
        <div class="row mx-0 mt-2 text-general px-2">
            <div class="col">
                <ValidationProvider v-slot="{errors}" rules="required" name="comentario">
                    <el-input
                    v-model="form.comentario"
                    placeholder="Por favor, escriba su comentario"
                    type="textarea"
                    :rows="3"
                    :maxlength="caracteres"
                    show-word-limit
                    />
                    <span class="text-danger f-10">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>
        <div class="row mx-0 px-3 justify-content-end my-2">
            <button slot="reference" type="button" class="btn btn-sm br-20 text-white bg-gr-general" name="button">
                Comentar
            </button>
        </div>
        <div class="overflow-auto custom-scroll" style="max-height:calc(100vh - 130px)">
            <div v-for="(data, indice) in listado" :key="indice" class="row mx-0 px-2 my-3 no-gutters">
                <img
                class="rounded-circle obj-cover"
                height="32"
                width="32"
                :src="_.defaultTo(data.foto_miniatura, '/img/no-imagen/sin_user.png')"
                />
                <div class="col chat-bubble ml-3" :class="data.estado == 'pagado' ? 'chat-bubble-pay' : '' ">
                    <div class="row mx-0 my-1 f-13 text-muted">
                        <div class="col-6 f-600">
                            <span v-if="!data.nombre" class="ml-2 text-general">COLOCAR NOMBRE DEL USUARIO</span>
                            <span class="f-16" v-text="data.nombre" />
                        </div>
                        <div v-if="data.estado" class="col-12">
                            <p class="f-15 f-600" style="color:#0892DD;">
                                Plan: Panaderías VIP - $726.000
                            </p>
                            <p class="f-15 f-600" style="color:#0892DD;">
                                Válido hasta Dic. de 2020 (4 Meses)
                            </p>
                        </div>
                        <div class="col-12">
                            <!-- eslint-disable-next-line vue/no-v-html -->
                            <p class="f-15" v-text="data.comentario" />
                        </div>
                        <div class="col-12 text-right">
                            <i class="text-muted" v-text="data.fecha" />
                        </div>
                    </div>
                    <div class="chat-bubble-arrow-left" />
                </div>
            </div>
            <div class="row mx-0 justify-center">
                <div class="br-12 border d-middle-center" style="width:162px;height:162px;">
                    <img src="/img/sin_datos/comment.svg" alt="" />
                </div>
                <div class="col-12 py-2">
                    <p class="text-center">
                        No se han hecho comentarios de pago
                    </p>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>
<script>
export default {
    props: {
        titulo: {
            type: String,
            default: 'Comentarios'
        },
        id: {
            type: Number,
            default: 0
        },
        modelo: {
            type: Number,
            required: false
        },
        caracteres: {
            type: Number,
            default: 750
        },
        contador: {
            type: Number,
            default: 0
        }
    },
    data(){
        return{
            form: {
                comentario: null
            },
            listado: [
                {
                    foto_miniatura: 'https://www.ashoka.org/sites/default/files/styles/medium_1600x1000/public/thumbnails/images/daniela-kreimer.jpg?itok=R89tVtb4',
                    estado: 'pagado',
                    nombre: 'Jean Claude',
                    cliente: 'prueba',
                    fecha: '11 nov de 2001',
                    comentario: 'Hola esto es un comentario de prueba de un comentario normal y corriente'
                },
                {
                    foto_miniatura: 'https://www.ashoka.org/sites/default/files/styles/medium_1600x1000/public/thumbnails/images/daniela-kreimer.jpg?itok=R89tVtb4',
                    estado: 'no_pagado',
                    nombre: 'Jean Claude',
                    cliente: 'prueba',
                    fecha: '11 nov de 2001',
                    comentario: 'Hola esto es un comentario de prueba de un comentario normal y corriente'
                },
                {
                    foto_miniatura: 'https://www.ashoka.org/sites/default/files/styles/medium_1600x1000/public/thumbnails/images/daniela-kreimer.jpg?itok=R89tVtb4',
                    estado: 'no_pagado',
                    nombre: 'Jean Claude',
                    cliente: 'prueba',
                    fecha: '11 nov de 2001',
                    comentario: 'Hola esto es un comentario de prueba de un comentario normal y corriente'
                },
                {
                    foto_miniatura: 'https://www.ashoka.org/sites/default/files/styles/medium_1600x1000/public/thumbnails/images/daniela-kreimer.jpg?itok=R89tVtb4',
                    estado: 'no_pagado',
                    nombre: 'Jean Claude',
                    cliente: 'prueba',
                    fecha: '11 nov de 2001',
                    comentario: 'Hola esto es un comentario de prueba de un comentario normal y corriente'
                }
            ]
        }
    }
}
</script>
<style lang="scss">
.chat-bubble{
    background: #F8FCFF;
    /* border: 1px solid var(--color-general); */
    border: 1px solid #F8FCFF;
}
.chat-bubble-pay{
    background: #F8FCFF;
    /* border: 1px solid var(--color-general); */
    border: 2px solid #0892DD;
}
.chat-bubble-right{
    background: #f9f9f9;
    border: 1px solid red;
}
.border-bottom-active{
    border-bottom: 3px solid #FF2525;
}
</style>
