import Cedis from "~/services/cedis";

export const state = {
    cedis: [],
    id_cedis: 0,
    monedas: [],
    id_moneda: null,
    moneda:{}
};

export const getters = {
    cedis: state => state.cedis,
    id_cedis: state => state.id_cedis,
    monedas: state => state.monedas,
    id_moneda: state => state.id_moneda,
    moneda: state => state.moneda
};

export const mutations = {
    set_cedis: (state, payload) => {
        state.cedis = payload;
    },
    set_id_cedis: (state, payload) => {
        state.id_cedis = payload;
    },
    set_monedas: (state, payload) => {
        state.monedas = payload;
    },
    set_id_moneda: (state, payload) => {
        state.id_moneda = payload;
        // commit("set_moneda",payload)
    },
    set_moneda: (state, payload) => {
        const moneda = state.monedas.find(o=>o.id == payload)
        state.moneda = moneda;
    },
};

export const actions = {
    async action_get_cedis({ commit },id_proyecto){
        const { data } = await Cedis.get_cedis(id_proyecto);
        data.unshift({id:0,nombre:'Todos', id_moneda: 0})
        commit("set_cedis", data);
    },
    async action_get_monedas({ commit }){
        const { data } = await Cedis.get_monedas();
        commit("set_monedas", data);
        if (data.length){
            commit("set_id_moneda", data[0].id);
            commit("set_moneda", data[0].id);
        }
    },
};
