<template>
    <div
    ref="modalgbp"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    >
        <div
        class="modal-dialog modal-dialog-centered"
        :class="tamano ? tamano : ''"
        role="document"
        >
            <div class="modal-content">
                <div class="modal-header mb-1 bg-modal dwad">
                    <!-- <img v-if="icon" class="img-tipo" height="88" :src="`/img/modales/${icon}.svg`" alt="" /> -->
                    <div class="col-auto f-17 text-black f-600">
                        {{ titulo }}
                    </div>
                    <div class="ml-auto my-auto d-middle-center bg-white rounded-circle" style="width:20px;height:20px;">
                        <i class="icon-cancel-circled-outline f-20 cr-pointer text-general" data-dismiss="modal" />
                    </div>
                </div>
                <div class="modal-body overflow-auto custom-scroll" style="max-height:50vh;">
                    <slot />
                </div>
                <div class="row mx-0 justify-center pb-3">
                    <button
                    type="button"
                    class="btn-cerrar"
                    @click="cancelar"
                    v-text="textosBotones.cancelar"
                    />
                    <button
                    v-show="!noAceptar"
                    v-loading="cargando"
                    type="button"
                    class="btn mx-2 btn-aceptar text-white br-10"
                    @click="guardar"
                    v-text="textosBotones.aceptar"
                    />
                    <button
                    v-show="eliminar"
                    v-loading="cargando"
                    type="button"
                    class="btn mx-2 btn-danger"
                    @click="actionEliminar"
                    v-text="textosBotones.eliminar"
                    />
                    <button
                    v-show="listo"
                    v-loading="cargando"
                    type="button"
                    class="btn mx-2 btn-general"
                    @click="toggle"
                    v-text="textosBotones.listo"
                    />
                    <button
                    v-show="adicional !== ''"
                    v-loading="cargando"
                    :disabled="desactivarGuardar"
                    type="button"
                    class="btn-adicional mx-2"
                    @click="onAdicional"
                    v-text="adicional"
                    />
                    <button
                    v-for="btn in btns"
                    v-show="btns.length"
                    :key="btn.texto"
                    type="button"
                    class="btn mx-2"
                    :class="btn.color"
                    @click="$emit('accion', btn.accion)"
                    v-text="btn.texto"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            required: true
        },
        tamano: {
            type: String,
            default: 'modal-md'
        },
        listo: {
            type: Boolean,
            default: false
        },
        eliminar: {
            type: Boolean,
            default: false
        },
        desactivarGuardar: {
            type: Boolean,
            default: false
        },
        adicional: {
            type: String,
            default: ''
        },
        noAceptar: {
            type: Boolean,
            default: false
        },
        btns: {
            type: Array,
            default: () => { return [] }
        },
        cargando: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            textosBotones: {
                listo: 'Listo',
                aceptar: 'Aceptar',
                guardar: 'Guardar',
                eliminar: 'Eliminar',
                cancelar: 'Cancelar'
            }
        }
    },
    methods: {
        toggle(){
            $(this.$refs.modalgbp).modal('toggle')
        },
        guardar(){
            this.$emit('guardar')
        },
        actionEliminar(){
            this.$emit('eliminar')
        },
        onAdicional(){
            this.$emit('adicional')
        },
        cancelar(){
            this.$emit('cancelar')
            this.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/sass/app.scss';


.modal-content{
    border: none;
    border-radius: 8px;

    .modal-header{
        // background:linear-gradient( rgba(50,163,253,.65) 1% , rgba(255,255,255,1) , rgba(255,255,255,1), rgba(255,255,255,1) );
        height: 60px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: none;
        background-size: cover;

        .close{
            outline: none;
            transition-duration: .85s;
            position: absolute;
            right: 10px;
            top: 1px;
            font-size: 2.5em;

            &:hover{
                transition-duration: .4s;
                transform: rotate(360deg) scale(1.2);
            }
        }
        .logo{
            display: block;
            // height: 70px;
            // width: 70px;

            span{
                font-size: 1.2em;
            }
        }
    }
    .modal-title{
        color: $azul2-primary;
        font-weight: normal;
    }
}
.img-tipo{
    position: absolute;
    top:20px;
    left: 50%;
    transform: translate(-50%);
}
.bg-modal{
    background-color: #F6F9FB;
    background-position:center;
}
</style>