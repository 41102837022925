<template>
    <modal
    ref="modalMapaGoogle"
    titulo="Localice su tienda"
    tamano="modal-xl"
    no-cancelar
    @guardar="cerrarModal"
    >
        <mapa-google
        ref="GoogleMaps"
        :coordenadas-entrada="coordenadas.entrada"
        @actualizar="actualizarCoordenadas"
        />
    </modal>
</template>

<script>
const COORDENADAS_DEFECTO = { lat: 0, lng: 0 }

export default {
    name: 'ModalGoogleMapa',
    props: {
        coordenadasEntrada: {
            type: Object,
            default: () => ({})
        }
    },
    data(){
        return {
            coordenadas: {
                entrada: COORDENADAS_DEFECTO,
                salida: COORDENADAS_DEFECTO
            }
        }
    },
    watch: {
        coordenadasEntrada: {
            immediate: true,
            deep: true,
            handler(coordenadas){
                this.coordenadas.entrada =  coordenadas
            }
        }
    },
    methods: {
        toggle(estaCerrando = false){
            this.$refs.modalMapaGoogle.toggle()
            if (!estaCerrando) this.dibujarMapa()
        },
        dibujarMapa(){
            this.$refs.GoogleMaps.dibujarMapa()
        },
        actualizarCoordenadas({ lat: latitud, lng: longitud }){
            this.coordenadas.salida = { latitud, longitud }
        },
        cerrarModal(){
            this.$emit('actualizar', this.coordenadas.salida)
            this.toggle(true)
        }
    }
}
</script>
