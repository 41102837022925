import Proyectos from '~/services/proyectos/proyectos'

export const state = {
    id_proyecto: null,
    detalle: {
        id: null,
        nombre: null,
        tipo: 1,
        estado: 1,
        descripcion: null,
        base_datos: null,
        dominio: null,
        bucket_s3: null,
        cant_tiendas: null,
        tiendas_activas: null,
        prefijos: []
    },
    userAdmin:[],
    paisesGuardados: [],
    estadosGuardados: []

}
export const getters = {
    id_proyecto: state => state.id_proyecto,
    detalle: state => state.detalle,
    userAdmin: state => state.userAdmin,
    paisesGuardados: state => state.paisesGuardados,
    estadosGuardados: state => state.estadosGuardados,

}

export const mutations = {
    set_id_proyecto: (state,payload) => {
        state.id_proyecto = payload;
    },
    set_detalle: (state,payload) => {
        state.detalle = payload;
    },
    set_userAdmin: (state,payload) => {
        state.userAdmin = payload;
    },
    set_paisesGuardados: (state,payload) => {
        state.paisesGuardados = payload;
    },
    set_estadosGuardados: (state,payload) => {
        state.estadosGuardados = payload;
    },

}
export const actions = {
    async action_detalle_proyecto({commit},payload){
        const {data} = await Proyectos.detalleProyecto(payload);
        commit("set_userAdmin", data.userAdmin);
        commit("set_paisesGuardados",data.paisesGuardados);
        commit("set_estadosGuardados",data.estadosGuardados);

        
    },
}
