import store from '~/store'

export default async(to, from, next) => {
  //console.log('acaaaaaaaaaaaaaaaaaaaaaa')
  
    if (!store.getters['auth/check'] && store.getters['auth/token']){
        try {
            await store.dispatch('auth/fetchUser')
            if(to.name === 'login'){
                return  next({name:'manager.administradores'})
            }
        } catch (e){ }
    }
    //console.log('no entro')
    next()
}
