<template>
    <div id="googleMap" ref="googleMap" class="googleMap" />
</template>

<script>
import { debounce } from 'lodash'

const OPCIONES_MAPA_DESACTIVADO = {
    panControl: false,
    zoomControl: false,
    scaleControl: false,
    draggable: false
}

const COORDENADAS_DEFECTO = { lat: 0, lng: 0 }
const EVENTO_CAMBIO_CENTRO = 'center_changed'
const ZOOM_INICIAL_MAPA = 18
const ICONO_MARCADOR_CENTRAL = '/img/icons/tienda-mapa.svg'

export default {
    name: 'GoogleMaps',
    props: {
        coordenadasEntrada: {
            type: Object,
            default: () => (COORDENADAS_DEFECTO)
        },
        desactivarMapa: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            coordenadasCentro: {},
            googleMaps: null,
            mapa: null,
            marcadores: []
        }
    },
    watch: {
        coordenadasEntrada: {
            deep: true,
            handler(){
                if(! this.desactivarMapa && (this.coordenadasCentro.lat !== 0 && this.coordenadasCentro.lng !== 0)) this.dibujarMapa()
            }
        }
    },
    methods: {
        async dibujarMapa(){
            console.log('dibujó', this.coordenadasCentro);
            this.calcularPosicionInicial()
            await this.inicializarGoogleMaps()
            this.agregarMarcadorCentral()
            this.agregarEventoMapa()
        },
        calcularPosicionInicial(){
            navigator.geolocation.getCurrentPosition(this.capturarCoordenadasNavegador, this.capturarCoordenadasDefecto, {})
        },
        capturarCoordenadasNavegador(coordenadas){
            const { latitude: lat, longitude: lng } = coordenadas.coords
            this.coordenadasCentro = { lat, lng }
        },
        capturarCoordenadasDefecto(){
            this.coordenadasCentro = Object.entries(this.coordenadasEntrada)
                .reduce((obj, [llave, valor]) => ({ ...obj, [llave]: +valor }), COORDENADAS_DEFECTO)
            ?? COORDENADAS_DEFECTO
        },
        async inicializarGoogleMaps(){
            this.googleMaps = await this.$iniciarGoogleMaps()

            this.mapa = new this.googleMaps.Map(
                this.$refs.googleMap, {
                    zoom: ZOOM_INICIAL_MAPA,
                    center: this.coordenadasCentro,
                    ...(this.desactivarMapa && { ...OPCIONES_MAPA_DESACTIVADO })
                })
        },
        agregarMarcadorCentral(){
            this.marcadores[0]= new this.googleMaps.Marker({
                position: this.coordenadasCentro,
                map: this.mapa,
                icon: ICONO_MARCADOR_CENTRAL,
                draggable: false
            })
        },
        agregarEventoMapa(){
            if (!_.isEmpty(this.marcadores)) this.googleMaps.event.addListener(this.mapa, EVENTO_CAMBIO_CENTRO, () => this.rebotador())
        },
        rebotador: debounce(function(){
            this.actualizarPosicionMarcador()
        }, 200),
        actualizarPosicionMarcador(){
            /* TODO: Quitar cuando ya el mapa esté estable */
            console.log('cambió');
            const nuevaPosicion = this.obtenerPosicionCentralActual()

            this.marcadores[0].setPosition(nuevaPosicion)

            this.$emit('actualizar', nuevaPosicion)
        },
        obtenerPosicionCentralActual(){
            const centro = this.mapa.getCenter()

            return {
                lat: this.formatearCifraCoordenadas(centro.lat()),
                lng: this.formatearCifraCoordenadas(centro.lng())
            }
        },
        formatearCifraCoordenadas: numero => +numero.toFixed(6),
    },
}
</script>

<style lang="scss" scoped>
.googleMap{
    position: relative;
    width: 100%;
    height: 500px;
}
</style>
