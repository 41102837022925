export default [
    {
        path: '/permisos',
        meta: { nombre: 'Proyectos' },
        name: 'manager.permisos',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/permisos').then(m => m.default || m),
        redirect: {name: 'manager.permisos.modulos'},
        children: [
            {
                path: 'modulos',
                meta: { nombre: 'Proyectos' },
                name: 'manager.permisos.modulos',
                component: () => import(/* webpackChunkName: "promociones" */ '../../pages/permisos/modulos').then(m => m.default || m),
            },
            {
                path: 'permisos',
                meta: { nombre: 'Proyectos' },
                name: 'manager.permisos.permisos',
                component: () => import(/* webpackChunkName: "promociones" */ '../../pages/permisos/permisos').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/crear-permiso',
        meta: { nombre: 'Proyectos' },
        name: 'manager.permisos.crearPermiso',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/permisos/crearPermiso').then(m => m.default || m),
    },
]