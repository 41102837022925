export default [
    {
        path: '/features-flags',
        name: 'manager.features-flags',
        redirect: { name: 'manager.features-flags.clientes' },
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/featuresFlags/index').then(m => m.default || m),
        children: [
            {
                path: 'clientes',
                meta: { nombre: 'Feature Flags' },
                name: 'manager.features-flags.clientes',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/featuresFlags/viewComponent').then(m => m.default || m)
            },
            {
                path: 'vendedor',
                meta: { nombre: 'Feature Flags' },
                name: 'manager.features-flags.vendedor',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/featuresFlags/viewComponent').then(m => m.default || m)
            },
            {
                path: 'delivery',
                meta: { nombre: 'Feature Flags' },
                name: 'manager.features-flags.delivery',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/featuresFlags/viewComponent').then(m => m.default || m)
            },
            {
                path: 'picking_packing',
                meta: { nombre: 'Feature Flags' },
                name: 'manager.features-flags.picking_packing',
                component: () => import(/* webpackChunkName: "tiendas" */ '../../pages/featuresFlags/viewComponent').then(m => m.default || m)
            },
        ],
    },
    {
        path: '/features-flags/crear-flag',
        name: 'manager.features-flags.crear-flag',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/featuresFlags/crearFeatureFlags').then(m => m.default || m),
    }
]
