<template>
    <section>
        <link rel="stylesheet" href="https://unpkg.com/leaflet-geosearch@2.6.0/assets/css/leaflet.css" />
        <v-map :zoom.sync="zoom" :center.sync="initialLocation" class="mapa" @click="markerEdit" @update:zoom="zoomUpdate">
            <v-tilelayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
            <!-- <v-locatecontrol /> -->
            <v-marker v-if="verMarker === 1" :lat-lng="marker" :icon="defaultIcon" />
        </v-map>
    </section>
</template>
<script>
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import { latLng, icon } from 'leaflet'

export default {
    components: {
        'v-map': LMap,
        'v-tilelayer': LTileLayer,
        'v-marker': LMarker
    },

    data(){
        return {
            initialLocation: [7.11703, -73.1217],
            geosearchOptions: {
                // provider: new OpenStreetMapProvider()
            },
            marker: latLng(7.11703, -73.1217),
            defaultIcon: icon({
                iconUrl: '/img/map-marker-red.png',
                iconSize: [38, 40],
                iconAnchor: [22, 1],
                popupAnchor: [-3, -76]
            }),
            verMarker: 0,
            zoom: 1,
            mapOptions: {
                zoomSnap: 0.5
            }
        }
    },

    watch: {
        marker(val){
            this.$emit('latlng', val)
        }
    },

    methods: {
        markerEdit(event){
            this.marker = event.latlng
            this.verMarker = 1
        },
        loadMapa(data){
            // this.marker = latLng(latitud, longitud)
            this.marker = data
            this.verMarker = 1
            this.initialLocation = data,
            this.currentZoom = 8
            this.zoom = 8
        },
        zoomUpdate(zoom){
            // this.currentZoom = zoom
        },
        reloadMapa(){
            // this.initialLocation = [7.11703, -73.1217],
            // this.geosearchOptions = {
            //     provider: new OpenStreetMapProvider()
            // },
            // this.marker = latLng(7.11703, -73.1217),
            // this.defaultIcon = icon({
            //     iconUrl: '/img/map-marker-red.png',
            //     iconSize: [38, 40],
            //     iconAnchor: [22, 1],
            //     popupAnchor: [-3, -76]
            // }),
            // this.verMarker = 0,
            // this.zoom = 1,
            // this.mapOptions = {
            //     zoomSnap: 0.5
            // }
            // this.currentZoom = 1
            // this.zoom = 1
            // this.center = L.latLng(47.413220, -1.219482)
        },
        LoadData(latlng){
            // this.marker = latlng
            // this.verMarker = 1
            // this.initialLocation = latlng,
            // this.currentZoom = 8
            // this.zoom = 8
        },
        unbicacionCiudad(latlng){
            console.log('unbicacionCiudad',latlng);
            this.marker = latlng
            this.verMarker = 1
            this.initialLocation = latlng,
            this.currentZoom = 8
            this.zoom = 8
        }
    }
}
</script>

<style lang="scss">
@import "~leaflet/dist/leaflet.css";
// @import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

.mapa {
  height: 500px;
  margin: 0;
}
.vue2leaflet-map {
    height: 100% !important;
    width: 100% !important;
}
</style>
