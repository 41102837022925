<template>
    <div class="main-layout">
        <menuLateral />
        <navbar />
        <div class="position-absolute app-wrapper px-3">
            <child />
        </div>
    </div>
</template>

<script>
import '../assets/sass/app.scss'
// import navigationDrawer from '~/components/drawer/navigation-drawer'
// import { getHostname } from '~/services/helper'
export default {
    name: 'MainLayout',
    components: {
        menuLateral: () => import('~/components/menuLateral')
    },
    computed: {
        toolbar: {
            get(){
                return this.$store.state.tema.dark
            },
            set(val){
                this.$store.commit('tema/setDark', val)
            }
        }
    },
    // sockets: {
    //     [`${getHostname}-notificaciones`](valor = ''){
    //         if (valor === '') return
    //         this.notificacion('Notificación', valor, 'success')
    //     }
    // },
    methods: {
        color(data){
            const root = document.documentElement
            root.style.setProperty('--general-color', data)
        }
    }
}
</script>
<style lang="scss" scoped>

main{
    padding-left: 65px !important
}
.app-wrapper {
    background: #F7F7FB;
    color: #5D5D5D;
    height: calc(100vh - 61px);
    width: calc(100vw - 235px);
    right: 0px;
    top: 60px;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: stretch;

    & > .full-height-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1 1 auto;

        .conf-section {
            flex: 1 1 auto;
            margin: 1.5rem 1rem;
            padding: 1rem;
        }

    }
}
</style>
