<template>
    <div class="row mx-0 my-3 f-15 px-3">
        <div class="mt-3" style="width:170px;">
            <div class="br-10 p-2 text-white my-2 text-center" :class="datos[0].color" style="width:140px;">
                <p>{{ datos[0].nombre }}</p>
                <p class="f-17 f-600">{{ datos[0].format === undefined?separadorNumero(datos[0].valor,0):datos[0].valor }}</p>
                <p v-show="datos[0].fecha">{{ datos[0].fecha }}</p>
            </div>
            <div class="br-10 p-2 text-white my-2 text-center" :class="datos[1].color" style="width:140px;">
                <p>{{ datos[1].nombre }}</p>
                <p class="f-17 f-600">{{ datos[1].format === undefined?separadorNumero(datos[1].valor,0):datos[1].valor }}</p>
                <p v-show="datos[1].fecha">{{ datos[1].fecha }}</p>
            </div>
            <div v-if="datos.length==3" class="br-10 p-2 text-white my-2 text-center" :class="datos[2].color" style="width:140px;">
                <p>{{ datos[2].nombre }}</p>
                <p class=" f-600">{{ datos[2].fecha }}</p>
                <p>{{ separadorNumero(datos[2].valor,0) }}</p>
            </div>
        </div>
        <div class="col position-relative">
            <echart :options="chart" />
            <div v-if="!chart.dataset.source.length" class="img-sindatos text-center" style="max-width:230px;">
                Aún no se han registrado datos
            </div>
        </div>
        <div v-if="datos.length>3" class="mt-3" style="width:170px;">
            <div class="br-10 bg-gr-red p-2 text-white my-2 text-center" :class="datos[2].color" style="width:140px;">
                <p>{{ datos[2].nombre }}</p>
                <p>{{ datos[2].format === undefined?separadorNumero(datos[2].valor,0):datos[2].valor }}</p>
            </div>
            <div class="br-10 bg-gr-purple p-2 text-white my-2 text-center" :class="datos[3].color" style="width:140px;">
                <p>{{ datos[3].nombre }}</p>
                <p>{{ datos[3].format === undefined?separadorNumero(datos[3].valor,0):datos[3].valor }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        datos: {
            type: Array,
            default: () => {
                return []
            }
        },
        chart: {
            type: Object,
            default: () => {
                return {}
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .img-sindatos{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
