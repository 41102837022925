export const state = {
    productos: [],
    id_producto:null,
    query:''
}

export const getters = {
    productos: state => state.productos,
    id_producto: state => state.id_producto,
    query: state => state.query,

}

export const mutations = {
    set_productos: (state,payload) => {

        state.productos.push(...payload);
    },
    vaciar_productos: (state,payload) => {

        state.productos = [];
    },
    set_query: (state,payload) => {

        state.query = payload;
    },
    set_id_producto: (state,payload) => {
        state.id_producto = payload
    },
    update_productos: (state,payload) => {

        //Procedemos a buscar el indice del producto para despues alterarlo
        let idx = state.productos.findIndex(p=>p.id === payload.id)
        state.productos[idx] = payload

        //Hacemos un copia de los productos
        let backup_productos = state.productos

        //Vaciamos la data para que la vista reaccione
        state.productos = []

        // recargamos la informacion
        state.productos = backup_productos

    },
    delete_producto: (state,payload) => {

        //Procedemos a buscar el indice del producto para despues eliminarlo
        let idx = state.productos.findIndex(p=>p.id === payload)
        console.log(idx);
        state.productos.splice(idx, 1);

        //Hacemos un copia de los productos
        let backup_productos = state.productos

        //Vaciamos la data para que la vista reaccione
        state.productos = []

        // recargamos la informacion
        state.productos = backup_productos

    },


}
