<template>
    <section class="bg-general">
        <div class="row mx-0 justify-center align-content-center " style="height: calc(100vh);background-image: url('/img/auth/background_siluet.svg');background-repeat: no-repeat;background-size: cover;">
            <div class="col-xl-4 col-lg-5 col-md-6 col-sm-6 col-6 panel-left position-relative">
                <div class="position-absolute h-100 w-100" style="left:0px;background: #FFFFFF;opacity:0.8;backdrop-filter:blur(19px);border-radius: 60px 0px 0px 0px;" />
                <div class="z-1 position-relative h-100 d-middle-center" style="left:0px;">
                    <img src="/img/auth/logo.svg" height="200px" />
                </div>
            </div>
            <div class="col-xl-4 col-lg-5 col-md-6 col-sm-6 col-6 panel-right login position-relative">
                <div class="row mx-0 justify-center pt-5 pb-3 mt-5">
                    <div class="col-xl-8 col-lg-10">
                        <p class="text-general f-600 f-38">
                            Bienvenido
                        </p>
                    </div>
                </div>
                <div class="row mx-0 justify-center">
                    <div class="col-xl-8 col-lg-10">
                        <label class="text-black pl-3 px-2 f-12">
                            Correo electrónico o teléfono
                        </label>
                        <el-input v-model="form.correo" class="w-100" placeholder="Correo" size="medium" />
                    </div>
                </div>
                <div class="row mx-0 justify-center mt-2">
                    <div class="col-xl-8 col-lg-10">
                        <label class="text-black f-12 pl-3 px-2">
                            Contraseña
                        </label>
                        <el-input v-model="form.password" placeholder="Contraseña" size="medium" class="w-100" show-password />
                    </div>
                </div>
                <div class="row mx-0 justify-center mt-4">
                    <div class="col-xl-8 col-lg-10">
                        <button type="button" class="btn-general-small w-100" @click="login">
                            Iniciar Sesion
                        </button>
                    </div>
                </div>
                <!-- <div class="z-1 position-absolute h-100 w-100" style="left:0px;background: #FFFFFF;opacity:0.8;backdrop-filter:blur(19px);border-radius: 0px 0px 60px 0px;" />
                <div class="position-absolute h-100 w-100 d-flex flex-column justify-content-center" style="left:0px;z-index:5;">
                </div> -->
                <!-- <div class="row mx-0 justify-center cr-pointer ">
                    <p class="cr-pointer text-white f-14 py-3">
                        Recuperar Contraseña
                    </p>
                </div> -->
            </div>
        </div>
        <div
        id="modalLogin"
        ref="el-modal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalLoginLabel"
        aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content br-10">
                    <div class="modal-header br-t-10" style="background-color: deepskyblue; !important;">
                        <h5 id="modalLoginLabel" class="modal-title w-100">
                            {{ modal.titulo }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-center" style="white-space: pre-line;">
                        {{ modal.mensaje }}
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
export default {
    components: {
    },
    layout: 'login',
    data(){
        return{
            modal: {
                titulo: '',
                mensaje: ''
            },
            form:{
                correo: '',
                password: ''
            }
        }
    },
    methods: {
        async login(){
            try {
                // Submit the form.
                const { data } = await axios.post('login-manager',this.form)

                // Save the token.
                this.$store.dispatch('auth/saveToken', {
                    token: data.token,
                    remember: false
                })

                // Fetch the user.
                await this.$store.dispatch('auth/fetchUser')

                // Redirect home.
                this.$router.push({ name: 'manager.administradores' })
            } catch (e){
                // console.log(e.response.data.tipo)
                let tmp = e.response.data
                if (tmp.tipo === 1){
                    this.modal.titulo = 'Credenciales no válidas'
                    this.modal.mensaje = tmp.mensaje
                    $(this.$refs['el-modal']).modal('toggle')
                    return
                }
                console.error(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>

.panel-left{
    border-radius: 60px 0px 0px 0px;
    height:500px;
}

.panel-right{
    border-radius: 0px 0px 60px 0px;
    height:500px;
}

.login{
    height:500px;
    background-color: rgba(255, 255, 255, 0.839);
    border-left: 1px solid #000000;
}
.bg-white{
    background: #FFFFFF;
    border-radius: 12px;
}
</style>
