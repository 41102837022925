export default [
    {
        path: '/tendero-vip/informacion',
        name: 'tendero-vip.info',
        redirect: { name: 'tendero-vip.info.preguntas' },
        meta: { nombre: 'información para los usuario' },
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informacion/tendero-vip').then(m => m.default || m),
        children: [
            {
                path: 'preguntas-frecuentes',
                name: 'tendero-vip.info.preguntas',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informacion/preguntas').then(m => m.default || m),
            },
            {
                path: 'terminos',
                name: 'tendero-vip.info.terminos',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informacion/terminos').then(m => m.default || m),
            },
            {
                path: 'politica',
                name: 'tendero-vip.info.politica',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informacion/politica').then(m => m.default || m),
            }
        ]
    }
]
