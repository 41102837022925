export default [
    {
        path: '/administradores',
        name: 'manager.administradores',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/administradores').then(m => m.default || m),
    },
    {
        path: '/administradores/crear',
        name: 'manager.administradores.crear',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/administradores/crearAdministrador').then(m => m.default || m),
    },
    {
        path: '/administradores/:idUser/editar',
        name: 'manager.administradores.editar',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/administradores/crearAdministrador').then(m => m.default || m),
    }
]