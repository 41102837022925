<template>
    <el-drawer :visible.sync="drawer" :with-header="false">
        <div class="bg-whitesmoke h-100">
            <div v-if="tipo=='chat'" class="row mx-0 align-items-center p-2 bg-grr-red text-white shadow-16">
                <i class="icon-cancel f-20 cr-pointer" @click="drawer=false" />
                <img class="rounded-circle mx-2 border bg-white" width="38" height="38" src="https://pbs.twimg.com/profile_images/1275576465153822722/XbkTvYrl.jpg" alt="" />
                <p class="f-600 tres-puntos">Diego</p>
                <p class="ml-auto">Chat</p>
                <i class="icon-star mx-2 text-aqua" />
            </div>
            <div v-else-if="tipo=='soporte'" class="row mx-0 align-items-center p-2 bg-grr-red text-white shadow-16">
                <i class="icon-cancel mr-2 f-20 cr-pointer" @click="drawer=false" />
                <b>Soporte</b>
                <!-- <p class="ml-auto cr-pointer" @click="inactivar">{{ estado?'Inactivar':'Activar' }} chat</p> -->
            </div>
            <div v-else class="row mx-0 align-items-center p-2 bg-grr-red text-white shadow-16">
                <i class="icon-cancel mr-2 f-20 cr-pointer" @click="drawer=false" />
                <b>{{ titulo }}</b>
            </div>
            <div class=" px-2" style="height:calc(100% - 55px)">
                <chat notific />
            </div>
        </div>
        <slot />
    </el-drawer>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        },
        tipo: {
            type: String,
            default: 'chat'
        },
        estado: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            drawer: false,
        };
    },
    methods:{
        toggle(){
            this.drawer = !this.drawer
        },
        inactivar(){
            this.$emit('inactivar')
        }
    }
}
</script>
<style lang="css">
    .text-aqua{
        color:#03D6BC;
    }
</style>
