
import Vue from 'vue'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es'

for (const rule in rules){
    // eslint-disable-next-line no-unused-expressions
    extend(rule, {
        ...rules[rule], // add the rule
        message: es.messages[rule] // add its message
    })
    extend("url", {
        validate: value => {
            if (value){
                return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
                    value
                )
            }

            return false;
        },
        message: "El campo debe ser tipo URL"
    });
}
Vue.component('ValidationProvider', ValidationProvider)

Vue.component('ValidationObserver', ValidationObserver)
