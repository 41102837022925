import axios from 'axios'

const API = 'proyectos'

const Proyectos = {
    listarProyectos(){
        return axios(`${API}/lista-proyectos`)
    },
    crearProyecto(payload={}){
        return axios.post(`${API}/crear-proyecto`, payload)
    },
    editarProyecto(payload = {}){
        return axios.put(`${API}/editar-proyecto`,payload)
    },
    activarInactivarProyecto(payload = {}){
        return axios.put(`${API}/activar-inactivar-proyecto`,payload)
    },
    eliminarProyecto(payload = {}){
        return axios.delete(`${API}/${payload.id}/eliminar-proyecto`)
    },
    getCedisProyecto(params){
        return axios(`${API}/get-cedis-proyecto`, { params });
    },
    getCedisUserProyecto(params){
        return axios(`${API}/get-cedis-user-proyecto`, { params });
    },
    crearUsuarioAdmin(payload = {}){
        return axios.post(`${API}/crear-usuario-admin`,payload)
    },
    editarUsuarioAdmin(payload = {}){
        return axios.put(`${API}/editar-usuario-admin`,payload)
    },
    detalleProyecto(payload={}){
        return axios(`${API}/detalle-proyecto`,{params:payload})
    },
    eliminarUserAdmin(payload = {}){
        return axios.delete(`${API}/${payload.id}/${payload.id_proyecto}/eliminar-user-admin`)
    },
    buscarPaises(payload = {}){
        return axios(`${API}/buscador-paises`,{params: payload})
    },
    ingresarPais(payload = {}){
        return axios.post(`${API}/ingresar-pais`,payload)
    },
    eliminarLugar(id_proyecto_ciudad = null ){
        return axios.delete(`${API}/${id_proyecto_ciudad}/eliminar-lugar`)
    },
    ingresarEstado(payload = {}){
        return axios.post(`${API}/ingresar-estado`,payload)
    },
    buscarEstados(payload = {}){
        return axios(`${API}/buscador-estados`,{params: payload})
    },
    listarEstadosProyecto(payload = {}){
        return axios(`${API}/estados`,{params: payload})
    },
    eliminarLugarCascada(id_proyecto_ciudad = null, tipo){
        return axios.delete(`${API}/${id_proyecto_ciudad}/${tipo}/eliminar-lugar-cascada`)
    },
    buscadorCiudades(payload = {}){
        return axios(`${API}/buscador-ciudades`,{params: payload})
    },
    listarCiudadesProyecto(payload = {}){
        return axios(`${API}/ciudades`,{params: payload})
    },
    ingresarCiudad(payload = {}){
        return axios.post(`${API}/ingresar-ciudad`,payload)
    },
    eliminarEstadoCascada(id_proyecto_ciudad = null,tipo){
        return axios.delete(`${API}/${id_proyecto_ciudad}/${tipo}/eliminar-estado-cascada`)
    },
    cambiarCobertura(payload = {}){
        return axios.put(`${API}/cambiar-cobertura`,payload)
    },
    listarComentarios(payload = {}){
        return axios(`${API}/${payload.id_proyecto}/listar-comentarios`)
    },
    crearComentarios(payload = {}){
        return axios.post(`${API}/crear-comentarios`,payload)
    },
    prefijosGuardados(payload = {}){
        return axios(`${API}/prefijos-guardados`,{params: payload})
    },
    eliminarPrefijo(id_prefijo){
        return axios.delete(`${API}/${id_prefijo}/eliminar-prefijo`)
    },
    agregarPrefijo(payload = {}){
        return axios.post(`${API}/agregar-prefijo`, payload)
    },
    editarPrefijo(payload = {}){
        return axios.post(`${API}/editar-prefijo`,payload)
    },

    // Nuevo
    postProyectoManager(model={}){
        return axios.post(`${API}/crear`, model)
    },
    putProyectoManager(model={}, id){
        return axios.put(`${API}/${id}/actualizar`, model)
    },
    getManagers(idProyecto){
        return axios.get(`${API}/administradores/listar?id_proyecto=${idProyecto}`)
    },
    changeStateUser(idUser, model){
        return axios.put(`${API}/${idUser}/administrador/change-state`, model)
    },
    getUser(idProyecto,idUser){
        return axios.get(`${API}/administrador/${idUser}/detalle?id_proyecto=${idProyecto}`)
    },
    getDataProject(idProject){
        return axios.get(`${API}/${idProject}/project`)
    },
    getDataProjectNavbar(idProject){
        return axios.get(`${API}/${idProject}/navbar/info`)
    },
    putInfoProject(idProject, model){
        return axios.put(`${API}/${idProject}/project/update`, model)
    },
    getStatesCountry(params){
        return axios.get(`${API}/pais/estados`, {params})
    },
    getCitiesStates(params){
        return axios.get(`${API}/estados/ciudades`, {params})
    },
    deleteState(idProject, idState){
        return axios.delete(`${API}/${idProject}/estado/${idState}/delete`)
    },
    deletePais(idProjectCountry){
        return axios.delete(`${API}/${idProjectCountry}/pais/delete`)
    },
	getModulosProyecto(idProyecto){
		return axios.get(`${API}/${idProyecto}/modulos-manager`)
	},
	editModuloProyecto(idProyecto, payload){
		return axios.put(`${API}/${idProyecto}/modulos-manager/edit`, payload)
	}
}

export default Proyectos
