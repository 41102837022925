export default [
    {
        path: '/admin/configurar',
        meta: { nombre: 'Configuración de tiendas' },
        component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/admin/configurar').then(m => m.default || m),
        children: [
            {
                path: 'tipo-tiendas',
                name: 'admin.conf.tipoTiendas',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/admin/configurar/tipoTiendas').then(m => m.default || m),
            },
            {
                path: 'distancia',
                name: 'admin.conf.distancia',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/admin/configurar/distancia').then(m => m.default || m),
            },
            {
                path: 'cobertura',
                name: 'admin.conf.cobertura',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/admin/configurar/cobertura').then(m => m.default || m),
            },
            {
                path: 'tiempos',
                name: 'admin.conf.tiempos',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/admin/configurar/tiempos').then(m => m.default || m),
            },
            {
                path: 'motivo-inactivo',
                name: 'admin.conf.motInactivo',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/admin/configurar/motInactivo').then(m => m.default || m),
            },
            {
                path: 'criterios-calificacion',
                name: 'admin.conf.criterios',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/admin/configurar/criterios').then(m => m.default || m),
            },
            {
                path: 'motivo-rechazo',
                name: 'admin.conf.motRechazo',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/admin/configurar/motRechazo').then(m => m.default || m),
            },
            {
                path: 'motivo-cancelacion-tendero',
                name: 'admin.conf.motCanTendero',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/admin/configurar/motCanTendero').then(m => m.default || m),
            },
            {
                path: 'motivo-cancelacion-cliente',
                name: 'admin.conf.motCanCliente',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/admin/configurar/motCanCliente').then(m => m.default || m),
            },
            {
                path: 'grupo-avatares',
                name: 'admin.conf.grupoAvatares',
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/admin/configurar/grupoAvatares').then(m => m.default || m),
            }
        ]
    }
]
