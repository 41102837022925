import { mapGetters } from 'vuex'
import Helper from '~/services/helper'
// import Aws from '~/services/aws'


export default {
    computed: {
        ...mapGetters({
            $usuario: 'auth/user',
        })
    },
    methods: {
        $validar(a){
            Helper.validar(a)
        },
        error_catch(e,referenciaValidador = null){
            Helper.error_catch(e)

            this.notificacion(
                _.get(e.response,'data.titulo','Error'),
                _.get(e.response,'data.mensaje','Ha ocurrido un error al realizar la consulta'),
                'error'
            )

            if(this.existenValidaciones(e) && referenciaValidador){
                this.$refs[referenciaValidador].setErrors(e.response.data.validaciones)
            }
        },
        existenValidaciones: excepcion => !_.isEmpty(excepcion?.response?.data?.validaciones),
        confirmar(message,title,callback = false,callback2 = false){
            this.$confirm(message,title,{
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                type: 'warning',
                center: true,
                dangerouslyUseHTMLString: true
            }).then(() => {
                if(callback) callback()
            }).catch(() => {
                if(callback2) callback2()
            })
        },
        aceptar(message,title,callback = false){
            this.$confirm(message,title,{
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                // cancelButtonText: 'No',
                type: 'warning'
                // center: true,
            }).then(() => {
                if(callback) callback()
            })
        },
        notificacion(title,message,type = 'info',isHtml = true){
            Helper.notificacion(title,message,type,isHtml)
        },
        crear_formData(data){
            const fd = new FormData()
            for(var key in data){
                if(Array.isArray(data[key])){
                    for(var key2 in Object.entries(data[key])){
                        fd.append(`${key}[${key2}]`,data[key][key2])
                    }
                } else {
                    fd.append(key,data[key])
                }
            }
            return fd
        },
        icon_extension(fileName){
            const fileTypes = {
                image: {
                    extension: ['bmp','gif','jpg','jpeg','png','svg','webp'],
                    icon: 'icon-picture color-IcoImagen'
                },
                pdf: {
                    extension: ['pdf'],
                    icon: 'icon-file-pdf-box color-IcoPdf'
                },
                video: {
                    extension: ['avi','mp4','mpg','mpeg','wmv','div'],
                    icon: 'icon-video color-IcoVideo'
                },
                other: {
                    icon: 'icon-doc-inv color-dark'
                }
            }
            const fileType = fileName.split('.').pop()
            const isImage = fileTypes.image.extension.includes(fileType.toLowerCase())
            const isPdf = fileTypes.pdf.extension.includes(fileType.toLowerCase())
            const isVideo = fileTypes.video.extension.includes(fileType.toLowerCase())
            if(isImage){
                return fileTypes.image.icon
            } else if(isPdf){
                return fileTypes.pdf.icon
            } else if(isVideo){
                return fileTypes.video.icon
            } else {
                return fileTypes.other.icon
            }
        },
        file_type(fileName){
            const fileTypes = {
                image: {
                    extension: ['bmp','gif','jpg','jpeg','png','svg','webp']
                },
                pdf: {
                    extension: ['pdf']
                },
                video: {
                    extension: ['mp4']
                    // extension: ['avi','mp4', 'mpg', 'mpeg', 'wmv', 'div'],
                }
            }
            const fileType = fileName.split('.').pop()
            const isImage = fileTypes.image.extension.includes(fileType.toLowerCase())
            const isPdf = fileTypes.pdf.extension.includes(fileType.toLowerCase())
            const isVideo = fileTypes.video.extension.includes(fileType.toLowerCase())
            if(isImage){
                return 'image/*'
            } else if(isPdf){
                return 'application/pdf'
            } else if(isVideo){
                return 'video/*'
            } else {
                return '*'
            }
        },

        /*
            Otorga el focus al input indicado por referencia dentro de un popover

            @Params:
            * referencia = String (nombre de la referencia del input)
            * index = Entero (índice de la iteración, en caso de estar en un v-for)
        */
        autofocus_popover(referencia,index = 0){
            setImmediate(x => {
                this.$nextTick(() => {
                    const input = this.$refs[referencia]

                    if(input.$el){this.$refs[referencia].$el.children[0].focus()}

                    if(input.length > 0) input[index].focus()
                    else input.focus()
                })
            })
        },

        async uploadCropperImage(file, path = 'img'){
            if(file === null){
                return null
            }
            if(file.image === null){
                return null
            }
            const archivo = Aws.b64toBlob(file.image)
            const name = file.name
            let ruta = `Market/${path}/${Aws.getHash()}.${name.split('.').pop()}`
            const resp = await Aws.getInstance().upload({
                Key: ruta,
                ContentType: archivo.type,
                Body: archivo
            }).on('httpUploadProgress',uploadEvent=>{
                let porcentaje = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                this.mensaje = porcentaje
            }).promise()

            return resp.Key
        },
        /**
         * Función para generar un delay
         * usada en un input tipo buscador
         * @Params:
            * callback = funcion
            * ms = duración en milisengundos del retraso
         */
        delay(callback,ms = 600){
            if(window.retraso) clearInterval(window.retraso)
            window.retraso = setTimeout(callback,ms)
        },
        rutaImagenMapa: () => '/img/otros/mapa-preview.png'
    }
}
