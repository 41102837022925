import Vue from 'vue'
import Global from '~/mixins/general'
import Formateadores from '~/mixins/Formateadores'
import FlipBook from '~/mixins/FlipBook'
import Vue2Filters from "vue2-filters";
import lodash from 'lodash'
import './vee-validate'
import './draggable'
import './slim'
import './google-maps'
// import moment from 'moment'
// moment.updateLocale('es', {
//     week: { dow: 0 }
// })
Vue.prototype._ = lodash
Vue.mixin(Global)
Vue.mixin(Formateadores)
Vue.mixin(FlipBook)
Vue.use(Vue2Filters)
