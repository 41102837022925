<template>
    <div class="navbar border-bottom d-flex justify-content-end">
        <div class="col-auto px-0 mx-2 mr-4">
            <el-select v-model="id_moneda" class="w-100" size="mini">
                <el-option
                v-for="item in monedas"
                :key="item.id"
                :label="`Moneda: ${item.nombre} - ${item.sigla}`"
                :value="item.id"
                />
            </el-select>
        </div>
        <p class="mx-2 ucfirst f-14 text-muted2 f-600"> {{ $usuario.nombre }}</p>
        <el-popover
        placement="bottom"
        trigger="click"
        >
            <p class="br-5 px-2 cr-pointer" @click="logout_admin">Cerrar Sesión</p>
            <img slot="reference" height="32" width="32" class="rounded-circle obj-cover border cr-pointer" :src="$usuario.imagen_firmada" alt="" />
        </el-popover>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import axios from 'axios'
export default {
    data(){
        return {
            proyectos:[],
            value:null
        }
    },
    computed:{
        ...mapGetters({
            cedis:'cedis/cedis',
            monedas:'cedis/monedas',
            // idm_moneda:'cedis/id_moneda',
        }),
        id_cedis: {
            get(){
                return this.$store.state.cedis.id_cedis
            },
            set(value){
                this.$store.commit('cedis/set_id_cedis', value)
            }
        },
        id_moneda: {
            get(){
                return this.$store.state.cedis.id_moneda
            },
            set(value){
                this.$store.commit('cedis/set_id_moneda', value)
                this.$store.commit('cedis/set_moneda', value)
            }
        },
        id_proyecto(){
            return Number(this.$route.params.id_proyecto)
        },
        name_route(){
            return this.$route.name
        }
    },
    mounted(){
        this.lista_proyectos()
        this.$store.dispatch('cedis/action_get_monedas')
       
    },
    methods:{
        async lista_proyectos(){
            try {
                const {data} = await axios(`manager-tiendas/lista-proyectos`)
                this.proyectos = data.proyectos

                if(this.id_proyecto){
                    this.value = this.id_proyecto
                    this.$store.dispatch('cedis/action_get_cedis',this.id_proyecto)
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        set_proyecto(id){
            this.$router.push({params:{id_proyecto:id}})
            this.$store.dispatch('cedis/action_get_cedis',id)
            this.$store.commit('cedis/set_id_cedis', 0)
        },
        logout_admin(){
            this.$store.dispatch('auth/logout')
            this.$router.push({name:'login'})
        },
    }
}
</script>

<style lang="css" scoped>
.navbar{
    position: fixed;
    height: 60px;
    z-index: 3;
    right: 0px;
    top: 0px;
    background: #ffffff;
    width: calc(100vw - 234px);
}
</style>
