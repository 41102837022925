import axios from "axios"

const API = "manager-tiendas";

const produccion = {
    get_datos(id_proyecto,id_cedis){
        return axios(`${API}/${id_proyecto}/listado?id_cedis=${id_cedis}`)
    },
    get_inactivos(){
        return axios(`${API}/get-inactivos`)
    },
    info_cabecera(payload){
        let params = {
            id_proyecto:payload.id_proyecto,
            id_tienda:payload.id_tienda
        }
        return axios(`${API}/info-cabecera`,{params})
    },
    historial_estados(params){
        return axios(`${API}/historial-estados`,{params})
    },
    info_tienda(id_proyecto,id_tienda){
        let params = {
            id_proyecto,
            id_tienda
        }
        return axios(`${API}/info-tienda`,{params})
    },
    cambiar_estrella(model){
        return axios.post(`${API}/cambiar-estrella`, model)
    },
}

export default produccion
