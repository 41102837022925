export default [
    {
        path: '/configurar',
        name: 'configurar',
        meta: { nombre: 'Configurar' },
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/configurar').then(m => m.default || m),
        children: [
            {
                path: 'aspecto-correos',
                meta: { nombre: 'Configurar' },
                name: 'aspecto-correos',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/configurar/menus/aspectoCorreos').then(m => m.default || m),
            },
            {
                path: 'logos-sistema',
                meta: { nombre: 'Configurar' },
                name: 'logos-sistema',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/configurar/menus/logoSistema').then(m => m.default || m),
            },
            {
                path: 'terminos-condiciones',
                meta: { nombre: 'Configurar' },
                name: 'terminos-condiciones',
                component: () => import(/* webpackChunkName: "informacion" */ '../../pages/configurar/menus/terminosCondiciones').then(m => m.default || m),
            }
        ]
    }
]