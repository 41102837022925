import Produccion from "~/services/tiendas/produccion";

export const state = {
    tienda: {
        id: null,
        nombre: '',
        id_plan: null,
        paz_salvo: '',
        logo_mini: '',
        logo_mini_firmado: '',
    }
};

export const getters = {
    tienda: state => state.tienda
};

export const mutations = {
    set_tienda: (state, payload) => {
        state.tienda = payload;
    }
};

export const actions = {
    async action_info_cabecera({ commit }, payload){
        const { data } = await Produccion.info_cabecera( payload );
        commit("set_tienda", data.info);
    },
};
